import * as CryptoJS from 'crypto-js';

const keyDefault =
  '9ikOICdvfkWEhZ7L6m8wHg0F9TKNMQMEW7nMxAued-AAyQAvDxjMFUq-90b-TZN9Jw';

const get = (key: string) => {
  key = `${key}`;
  return localStorage.getItem(key);
};

const getEncripted = (key: string) => {
  key = `${key}`;

  const value = localStorage.getItem(key);
  return value
    ? CryptoJS.AES.decrypt(value, keyDefault).toString(CryptoJS.enc.Utf8)
    : null;

  // return localStorage.getItem(key);
};

const set = (key: string, value: any) => {
  key = `${key}`;
  return localStorage.setItem(key, value);
};

const setEncripted = (key: string, value: any) => {
  key = `${key}`;
  value = CryptoJS.AES.encrypt(value, keyDefault).toString();
  return localStorage.setItem(key, value);
  // return localStorage.setItem(key, value);
};

const remove = (key: string) => {
  key = `${key}`;
  return localStorage.removeItem(key);
};

const storage = {
  get,
  set,
  remove,
  setEncripted,
  getEncripted,
};

export { storage };
(window as any)['storage'] = storage;
