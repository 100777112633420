import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { CacheService } from 'src/app/shared/services/cache.service';
import { environment } from 'src/environments/environment';

import { TOKEN_ANONYMOUS } from '../constants';
import { SecurityService } from './security.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private cacheService: CacheService,
    private securityService: SecurityService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.get('x-skip-autorizacao')) {
      return next.handle(
        req.clone({
          headers: req.headers.delete('x-skip-autorizacao'),
          setHeaders: {
            Authorization: `${TOKEN_ANONYMOUS}`,
          },
        })
      );
    }

    const userInfo = this.cacheService.getLoggedUserInfo();
    const token = userInfo.token;

    if (!userInfo || !token) {
      console.error('[AutorizacaoInterceptor] - Usuário não está logado');
      this.securityService.logout();
      return next.handle(req);
    }

    const authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    return next.handle(authReq).pipe(
      catchError((err) => {
        if (token) {
          if (err.status === 401) {
            console.error(
              '[AutorizacaoInterceptor] - Usuário não está Autorizado 401'
            );
            this.securityService.logout();
          }
        }
        return throwError(() => err);
      })
    );
  }

  errorHandler(error: any) {
    if (!environment.production) {
      console.error(error);
    }
  }
}
