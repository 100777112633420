import { AutoFocusDirective } from './directives/auto-focus.directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonContactComponent } from './components/button-contact/button-contact.component';
import { CommonModule } from '@angular/common';
import { MainTemplateComponent } from './components/main-template/main-template.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ButtonContactComponent, AutoFocusDirective, MainTemplateComponent],
  imports: [CommonModule, RouterModule, MatProgressBarModule, BsDropdownModule],
  exports: [ButtonContactComponent, MainTemplateComponent],
})
export class SharedModule {}
