import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule } from '@angular/common';
import { ErrorsComponent } from './errors/errors.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { MainTemplateComponent } from '../shared/components/main-template/main-template.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [ErrorsComponent, ForbiddenComponent],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    SharedModule,
    MatProgressBarModule,
  ]
})
export class CoreModule {}
