import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContact } from 'src/app/models/interfaces/IContact';

@Component({
  selector: 'app-button-contact',
  templateUrl: './button-contact.component.html',
  styleUrls: ['./button-contact.component.scss'],
})
export class ButtonContactComponent {
  @Input() size = 'normal';
  @Input() styleClass = 'btn-primary';
  @Input('data') contactData: IContact;
  @Output() clickButton = new EventEmitter<string>();

  constructor() {}

  async ngOnInit() {}

  onClickButton() {
    this.clickButton.emit();
  }
}
