<header class="main__header">
  <div class="main__header__row">
    <div class="appito-container">
      <div class="main__header__nav">
        <div class="nav-mobile" dropdown container="body">
          <a dropdownToggle aria-controls="dropdown-nav" class="d-md-none">
            <span class="material-symbols-outlined"> menu </span>
          </a>
          <ul
            id="dropdown-nav"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-header-main"
            role="menu"
            aria-labelledby="button-basic"
          >
            <li role="menuitem">
              <a
                class="dropdown-item typography title t-14"
                [routerLink]="['/']"
                >Reservar quadra</a
              >
            </li>
            <li role="menuitem">
              <a
                class="dropdown-item typography title t-14"
                [routerLink]="['/unidades']"
                >Unidades</a
              >
            </li>
          </ul>
          <div class="logo" [routerLink]="['/']">
            <img src="assets/images/appito-logo-white.svg" alt="Appito" />
          </div>
        </div>

        <a class="nav-item typography title t-14" [routerLink]="['/']"
          >Reservar quadra</a
        >
        <a
          class="nav-item typography title t-14"
          (click)="logEventAnalytics('Unidades-Header')"
          [routerLink]="['/unidades']"
          >Unidades</a
        >
      </div>

      <ng-container>
        <div class="coln-right">
          <button
            class="btn-primary btn-radius-16"
            (click)="navigateToLogin()"
            *ngIf="!isLogged"
          >
            Acessar conta
          </button>
          <ng-container *ngIf="isLogged">
            <div class="p-relative">
              <div dropdown container="body">
                <div
                  class="button-logged"
                  dropdownToggle
                  aria-controls="dropdown-basic"
                  (click)="logEventAnalytics('Minha-Conta-Header')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.9942 24C10.3532 24 8.8089 23.6864 7.36139 23.0591C5.91389 22.4395 4.63667 21.576 3.52975 20.4685C2.43058 19.3611 1.56749 18.0833 0.940493 16.635C0.313498 15.1868 0 13.6418 0 12C0 10.3582 0.313498 8.81317 0.940493 7.36496C1.56749 5.91675 2.43058 4.64279 3.52975 3.54308C4.63667 2.43562 5.91001 1.56825 7.34978 0.940949C8.79729 0.31365 10.3416 0 11.9826 0C13.6313 0 15.1795 0.31365 16.627 0.940949C18.0745 1.56825 19.3517 2.43562 20.4586 3.54308C21.5656 4.64279 22.4325 5.91675 23.0595 7.36496C23.6865 8.81317 24 10.3582 24 12C24 13.6418 23.6865 15.1868 23.0595 16.635C22.4325 18.0833 21.5656 19.3611 20.4586 20.4685C19.3517 21.576 18.0745 22.4395 16.627 23.0591C15.1795 23.6864 13.6352 24 11.9942 24ZM12.0058 22.0252C12.865 22.0252 13.7281 21.909 14.5951 21.6767C15.4698 21.4443 16.2941 21.1075 17.0682 20.666C17.8423 20.2246 18.5235 19.6902 19.1118 19.0629C18.6705 18.3892 18.0861 17.8199 17.3585 17.3553C16.6386 16.8829 15.822 16.5266 14.9086 16.2865C14.0029 16.0387 13.0353 15.9148 12.0058 15.9148C10.9531 15.9148 9.97001 16.0387 9.0566 16.2865C8.1432 16.5344 7.33043 16.8945 6.61829 17.3669C5.90614 17.8316 5.32946 18.3969 4.88824 19.0629C5.4688 19.6902 6.14611 20.2246 6.92017 20.666C7.70198 21.1075 8.52637 21.4443 9.39332 21.6767C10.2603 21.909 11.1311 22.0252 12.0058 22.0252ZM12.0058 13.9051C12.7567 13.9129 13.4378 13.7231 14.0493 13.3359C14.6609 12.9409 15.1447 12.4105 15.5007 11.7444C15.8568 11.0707 16.0348 10.3156 16.0348 9.47919C16.0348 8.68925 15.8529 7.96515 15.4891 7.30687C15.133 6.6486 14.6493 6.12197 14.0377 5.72701C13.4262 5.3243 12.7489 5.12294 12.0058 5.12294C11.2472 5.12294 10.5622 5.3243 9.95065 5.72701C9.33914 6.12197 8.85148 6.6486 8.48766 7.30687C8.13159 7.96515 7.95743 8.68925 7.96517 9.47919C7.96517 10.3156 8.1432 11.0668 8.49927 11.7328C8.85535 12.3988 9.33527 12.9255 9.93904 13.3127C10.5506 13.6999 11.2395 13.8974 12.0058 13.9051Z"
                      fill="#41433C"
                      fill-opacity="0.6"
                    />
                  </svg>
                  <span class="typography title t-14">Minha conta</span>
                  <span class="material-symbols-outlined">expand_more</span>
                </div>

                <ul
                  id="dropdown-basic"
                  *dropdownMenu
                  class="dropdown-menu dropdown-menu-logged"
                  role="menu"
                  aria-labelledby="button-basic"
                >
                  <li role="menuitem">
                    <a
                      class="dropdown-item"
                      [routerLink]="['/minha-conta/minhas-reservas']"
                      (click)="logEventAnalytics('Minhas-Reservas-Header')"
                      ><span class="typography body t-14"
                        >Minhas reservas</span
                      ></a
                    >
                  </li>
                  <li role="menuitem">
                    <a
                      class="dropdown-item"
                      [routerLink]="['/minha-conta/dados-conta']"
                      (click)="logEventAnalytics('Minhas-Reservas-DadosConta')"
                      ><span class="typography body t-14"
                        >Dados da conta</span
                      ></a
                    >
                  </li>
                  <li role="menuitem">
                    <a
                      class="dropdown-item"
                      [routerLink]="['/minha-conta/qr-code']"
                      (click)="logEventAnalytics('Minhas-Reservas-QRCode')"
                      ><span class="typography body t-14"
                        >QR code de acesso</span
                      ></a
                    >
                  </li>
                  <li role="menuitem">
                    <a class="dropdown-item" (click)="logout()"
                      ><span class="typography body t-14 text-error"
                        >Sair</span
                      ></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="main__header__row--2">
    <ng-content select="[header]" *ngIf="customHeader; else headerCommom"></ng-content>
    <ng-template #headerCommom>
      <div class="appito-container" *ngIf="!customHeader">
        <p class="typography title t-24">{{ title }}</p>
      </div>
    </ng-template>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loader"></mat-progress-bar>
</header>
<main class="main__body">
  <ng-content select="[content]"></ng-content>
</main>

<footer class="main__footer">
  <div class="faq" *ngIf="showFaq">
    <div class="appito-container">
      <div class="coln-1">
        <h2 class="typography title t-24 text-black">Dúvidas frequentes</h2>
        <p class="typography body t-14 text-primary">
          Encontre aqui as respostas para as principais dúvidas na hora de
          reservar uma quadra na Appito Arena.
        </p>
        <p class="typography body t-14 text-primary d-none d-md-inline-flex">
          Segue com dúvidas? Sem problemas!<br />
          Entre em contato com nossa equipe de atendimento via WhatsApp e
          faremos todo o possível para te ajudar.
        </p>
        <app-button-contact
          class="d-none d-md-inline-flex"
          [data]="contactData"
          (clickButton)="onClickButtonContact()"
        ></app-button-contact>

        <div style="width: 32px; height: 19px"></div>

        <div class="divider d-none d-md-inline-flex"></div>

        <img
          src="https://storage.googleapis.com/partnerhub_partner_badges_prod/D_60ad631d685f5b00196a737b.png"
          alt="Stone"
          style="width: 101px; height: 48px"
          class="d-none d-md-inline-flex"
        />
      </div>
      <div class="coln-2" *ngIf="faq">
        <div
          class="faq__item"
          *ngFor="let faqItem of faq"
          [class.faq__item--expanded]="faqItem.expanded"
        >
          <div class="faq__item-question" (click)="collapse(faqItem)">
            <p class="typography title t-18">{{ faqItem.question }}</p>
            <span class="material-symbols-outlined">
              {{ faqItem.expanded ? "expand_less" : "expand_more" }}
            </span>
          </div>

          <div class="line" *ngIf="faqItem.expanded"></div>
          <div class="faq__item-answer" [@collapse]="!faqItem.expanded">
            <div
              class="typography body t-14 text-primary"
              [innerHtml]="faqItem.answer"
            ></div>
          </div>
        </div>
      </div>

      <p class="typography body t-14 text-primary d-md-none">
        Segue com dúvidas? Sem problemas!<br />
        Entre em contato com nossa equipe de atendimento via WhatsApp e faremos
        todo o possível para te ajudar.
      </p>
      <app-button-contact
        class="d-md-none"
        [data]="contactData"
        (clickButton)="onClickButtonContact()"
      ></app-button-contact>

      <div class="divider d-md-none"></div>

      <img
        src="https://storage.googleapis.com/partnerhub_partner_badges_prod/D_60ad631d685f5b00196a737b.png"
        alt="Stone"
        style="width: 101px; height: 48px"
        class="d-md-none"
      />
    </div>
  </div>

  <div class="main__footer__breadcrumb" *ngIf="showFooterBread">
    <div class="appito-container">
      <div class="logo d-none d-md-inline-flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="61"
          height="20"
          viewBox="0 0 61 20"
          fill="none"
        >
          <g clip-path="url(#clip0_405_2475)">
            <path
              d="M11.9024 11.2386C11.9293 11.1185 11.9506 10.9952 11.9664 10.8688L12.6444 7.03569L12.9668 5.20329H10.8333L10.6579 6.19416C9.925 5.29607 8.94838 4.62871 7.84537 4.27229C6.74236 3.91587 5.55989 3.88555 4.44006 4.18497C3.32023 4.48439 2.31069 5.10081 1.53275 5.96015C0.75481 6.8195 0.241568 7.88522 0.0547105 9.02922C-0.132147 10.1732 0.0153307 11.3469 0.47941 12.4091C0.94349 13.4713 1.70443 14.3769 2.67079 15.0171C3.63715 15.6572 4.76781 16.0047 5.9269 16.0178C7.086 16.0308 8.22421 15.7089 9.20475 15.0907H11.2126L11.9024 11.2386ZM6.01641 13.9244C5.24058 13.9239 4.48231 13.6934 3.83745 13.2621C3.1926 12.8307 2.69012 12.2178 2.39354 11.5009C2.09697 10.784 2.01962 9.99524 2.17126 9.23437C2.32291 8.4735 2.69674 7.77468 3.2455 7.22624C3.79427 6.67781 4.49332 6.30441 5.25428 6.15322C6.01524 6.00204 6.80394 6.07986 7.52067 6.37687C8.2374 6.67387 8.84999 7.17672 9.28097 7.82184C9.71195 8.46695 9.94198 9.22537 9.94198 10.0012C9.94032 10.235 9.91811 10.4682 9.8756 10.6981L9.85427 10.819C9.66573 11.6973 9.18205 12.4845 8.48375 13.0495C7.78545 13.6145 6.91467 13.9233 6.01641 13.9244Z"
              fill="#41433C"
            />
            <path
              d="M32.7463 3.98718C31.1832 3.98891 29.6821 4.59868 28.5605 5.68746C27.439 6.77625 26.7851 8.25865 26.737 9.82101L25.3147 17.8808L24.9402 20.0142H27.0736L28.1427 13.8793C28.7929 14.6539 29.6279 15.2521 30.5705 15.6185C31.5131 15.9849 32.5329 16.1076 33.5355 15.9754C34.5381 15.8432 35.4912 15.4602 36.3066 14.862C37.122 14.2638 37.7734 13.4696 38.2005 12.553C38.6277 11.6363 38.8167 10.6267 38.7502 9.61756C38.6837 8.60844 38.3638 7.63239 37.8201 6.77968C37.2764 5.92697 36.5264 5.22514 35.6395 4.73911C34.7527 4.25308 33.7576 3.99854 32.7463 3.99904V3.98718ZM32.7463 13.9244C31.9703 13.9244 31.2118 13.6943 30.5667 13.2632C29.9215 12.8321 29.4187 12.2194 29.1217 11.5025C28.8248 10.7856 28.7471 9.99682 28.8985 9.23579C29.0498 8.47477 29.4235 7.77572 29.9722 7.22705C30.5208 6.67838 31.2199 6.30473 31.9809 6.15336C32.7419 6.00198 33.5308 6.07967 34.2476 6.37661C34.9645 6.67355 35.5772 7.17639 36.0083 7.82156C36.4394 8.46673 36.6695 9.22524 36.6695 10.0012C36.6682 11.0413 36.2545 12.0384 35.519 12.7739C34.7835 13.5094 33.7864 13.9231 32.7463 13.9244Z"
              fill="#41433C"
            />
            <path
              d="M54.1424 3.98716C54.0049 3.98716 53.8698 3.9919 53.7371 4.00138V3.98716H47.4481L47.7752 2.12394L48.1498 -0.00952148H46.0163L45.6418 2.12394L45.3146 3.98716H43.9871L43.6126 6.12062H44.9401L43.7264 12.9951L43.3518 15.1286H45.4853V15.0764L47.0688 6.09454H49.5792C48.6846 7.1343 48.1725 8.44872 48.1278 9.81963C48.0831 11.1905 48.5086 12.5355 49.3337 13.6313C50.1587 14.7271 51.3336 15.5078 52.6635 15.8438C53.9933 16.1798 55.3981 16.051 56.6446 15.4786C57.8912 14.9063 58.9045 13.9249 59.5164 12.6974C60.1284 11.4698 60.3021 10.0699 60.0088 8.72994C59.7155 7.39002 58.9728 6.1907 57.904 5.33101C56.8352 4.47133 55.5046 4.003 54.1329 4.00375L54.1424 3.98716ZM54.1424 13.9243C53.3664 13.9248 52.6076 13.6951 51.9622 13.2643C51.3167 12.8335 50.8135 12.221 50.5162 11.5041C50.2189 10.7873 50.1408 9.9984 50.2919 9.23722C50.4431 8.47604 50.8165 7.77677 51.3651 7.22786C51.9137 6.67896 52.6127 6.30508 53.3738 6.15351C54.1349 6.00194 54.9238 6.07949 55.6408 6.37636C56.3579 6.67323 56.9707 7.17607 57.4019 7.82129C57.8331 8.46651 58.0632 9.22511 58.0632 10.0011C58.062 11.0408 57.6486 12.0376 56.9136 12.773C56.1787 13.5084 55.1821 13.9225 54.1424 13.9243Z"
              fill="#41433C"
            />
            <path
              d="M40.7918 4.93059H40.7894L39.3695 12.9856L38.995 15.1096H41.1189L42.7048 6.11348H42.7072L43.0817 3.9895H40.9577L40.7918 4.93059Z"
              fill="#41433C"
            />
            <path
              d="M19.4239 3.98718C17.8608 3.98891 16.3597 4.59868 15.2382 5.68746C14.1167 6.77625 13.4627 8.25865 13.4147 9.82101L11.9924 17.8808L11.6178 20.0142H13.7513L14.8275 13.8793C15.4791 14.6517 16.3149 15.2474 17.2576 15.6114C18.2003 15.9754 19.2195 16.096 20.2211 15.9621C21.2227 15.8282 22.1744 15.444 22.9884 14.8451C23.8023 14.2462 24.4522 13.4518 24.878 12.5354C25.3038 11.619 25.4918 10.61 25.4247 9.60169C25.3576 8.5934 25.0375 7.61827 24.4939 6.76638C23.9504 5.91448 23.201 5.21328 22.3148 4.72753C21.4287 4.24179 20.4345 3.98717 19.4239 3.98718ZM19.4239 13.9244C18.6481 13.9239 17.8898 13.6934 17.245 13.262C16.6001 12.8307 16.0976 12.2178 15.8011 11.5009C15.5045 10.784 15.4271 9.99521 15.5788 9.23434C15.7304 8.47347 16.1043 7.77464 16.653 7.22621C17.2018 6.67778 17.9008 6.30437 18.6618 6.15319C19.4228 6.002 20.2115 6.07983 20.9282 6.37684C21.6449 6.67384 22.2575 7.17669 22.6885 7.82181C23.1195 8.46692 23.3495 9.22534 23.3495 10.0012C23.3482 11.0417 22.9342 12.0392 22.1982 12.7747C21.4622 13.5103 20.4644 13.9237 19.4239 13.9244Z"
              fill="#41433C"
            />
          </g>
          <defs>
            <clipPath id="clip0_405_2475">
              <rect width="60.1565" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="app-breadcrumb" *ngIf="breadcrumb">
        <ng-container *ngFor="let bread of breadcrumb; let i = index">
          <span class="typography title t-14 text-primary">{{ bread }}</span>
          <span
            class="material-symbols-outlined"
            *ngIf="i < breadcrumb.length - 1"
          >
            chevron_right
          </span>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="main__footer__row">
    <div class="appito-container">
      <nav class="main__footer__nav" *ngIf="listTerms$ | async as listTerms">
        <a
          [href]="term.description"
          [title]="term.title"
          target="_blank"
          class="typography title t-12"
          *ngFor="let term of listTerms"
          >{{ term.title }}</a
        >
      </nav>
      <div class="main__footer__copyright typography title t-12 text-tertiary">
        Appito Soluções Tecnológicas LTDA | 22.260.114/0001-03
      </div>
    </div>
  </div>
</footer>
