import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCIXgnioe7T4x1j8003EarpyFfcx-jHDGQ",
  authDomain: "appito-reserva-online.firebaseapp.com",
  projectId: "appito-reserva-online",
  storageBucket: "appito-reserva-online.appspot.com",
  messagingSenderId: "983663269969",
  appId: "1:983663269969:web:7f2ecba42df2c002278070",
  measurementId: "G-VY5BBM7Y0X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
