import { Component, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        let currentUrlSlug = event.url.slice(1);
        if (currentUrlSlug) {
          let arr = currentUrlSlug.split('/');
          arr.forEach((item) => {
            this.renderer.setAttribute(
              document.body,
              'class',
              item.split('?')[0]
            );
          });
        } else {
          this.renderer.setAttribute(document.body, 'class', '');
        }
      }
    });
  }
}
